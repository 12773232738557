<template>
    <WorkTeam />
</template>

<script>
import WorkTeam from '@/components/WorkTeam.vue'

export default {
  name: 'Employees',
  
  components: {
    WorkTeam,
  },

  data: () => ({
    //
  }),

};
</script>


